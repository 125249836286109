<template>
  <div class="content-container">
    <!-- pc端 -->

    <div class="pc-container">
      <!-- 上边布局 -->
      <swiper :options="swiperOption1" ref="homeSwiper1" @mouseenter.native="mouseEnter"
          @mouseleave.native="mouseLeave">
        <swiper-slide v-for="(item, index) in orgList" :key="index">
          <div class="top">
            <div class="left">
              <h1>机构会员</h1>
              <div class="introduction">
                <div class="introduction-top">{{ item.descTop }}</div>
                <div class="introduction-bottom" v-if="item.descBottom">{{ item.descBottom }}</div>
              </div>
              <div class="price">
                <!-- <span>企业机构 </span> -->
                <span>9999元/年</span>
              </div>
              <div class="btn" @click="handleEnterApply">入驻申请</div>
            </div>
            <div class="right">
              <div class="bg-container">
                <div class="bg"></div>
                <img :src="item.pc.img" alt="">
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- 下边布局 -->
      <swiper :options="swiperOption2" ref="homeSwiper2">
        <swiper-slide v-for="(item, index) in personList" :key="index">
          <div class="bottom">
            <div class="left">
              <h1>个人会员</h1>
              <div class="introduction">
                <div class="introduction-top">{{ item.descTop }}</div>
                <div class="introduction-bottom" v-if="item.descBottom">{{
                  item.descBottom }}</div>
              </div>
              <div class="scan-code">
                <img
                  src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/pc-scanCode-01.png"
                  alt="">
                <div class="txt">扫码试用</div>
              </div>
            </div>
            <div class="right">
              <div class="bg-container">
                <div class="bg"></div>
                <img :src="item.pc.img" alt="">
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- h5端 机构会员 -->
    <div class="h5-container">
      <swiper :options="swiperOption1" ref="homeSwiper1">
      <swiper-slide v-for="(item, index) in orgList" :key="index">
      <h1>机构会员</h1>
      <!-- <ul>
        <li class="" :class="[orgTabIndex == index ? 'active' : 'default', { 'mr-12': orgList.length != index + 1 }]"
          v-for="(item, index) in orgList" :key="index" @click="handleChangeIndex1(index)">
          {{ item.title }}
        </li>
      </ul> -->
      <div class="introduction">
        <div class="introduction-top">{{ item.descTop }}</div>
        <div class="introduction-bottom" v-if="item.descBottom">{{ item.descBottom }}
        </div>
      </div>
      <div class="price">
        <!-- <span>企业机构 </span> -->
        <span>9999元/年</span>
      </div>
      <div class="btn d-flex flex-center" @click="handleEnterApply">入驻申请</div>
      <div class="bg-container">
        <div class="bg"></div>
        <img :src="item.h5.img" alt="">
      </div>
    </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- h5端 个人会员 -->
    <div class="h5-container">
      <swiper :options="swiperOption2" ref="homeSwiper2">
        <swiper-slide v-for="(item, index) in personList" :key="index">
      <h1>个人会员</h1>
      <!-- <ul>
        <li class="" :class="[personTabIndex == index ? 'active' : 'default', { 'mr-12': personList.length != index + 1 }]"
          v-for="(item, index) in personList" :key="index" @click="handleChangeIndex2(index)">
          {{ item.title }}
        </li>
      </ul> -->
      <div class="introduction">
        <div class="introduction-top">{{ item.descTop }}</div>
        <div class="introduction-bottom" v-if="item.descBottom">{{
          item.descBottom }}</div>
      </div>
      <div class="scan-code">
        <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/pc-scanCode-01.png"
          alt="">
        <div class="txt">扫码试用</div>
      </div>
      <div class="bg-container">
        <div class="bg"></div>
        <img :src="item.h5.img" alt="">
      </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

  </div>
</template>
<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      orgList: [
        {
          id: 1,
          title: '荟客厅',
          desc: '微信小程序官网 社群引流',
          descTop: '微信小程序官网',
          descBottom: '社群引流',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/06.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/05.png',
          }
        },
        {
          id: 2,
          title: 'AI助理',
          desc: '个性化营销 更多线索',
          descTop: '个性化营销 ',
          descBottom: '更多线索',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/08.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/07.png',
          }
        },
        {
          id: 3,
          title: '活动营销',
          desc: '专业办会 高效转化',
          descTop: '专业办会 ',
          descBottom: '高效转化',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/10.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/09.png',
          }
        },
        {
          id: 4,
          title: '机构动态',
          desc: '内容与互动营销 私域留存',
          descTop: '内容与互动营销',
          descBottom: '私域留存',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/12.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/11.png',
          }
        },
      ],
      orgTabIndex: 0,
      personList: [
        {
          id: 1,
          title: '个人名片与主页',
          desc: '便捷商务社交',
          descTop: '便捷商务社交',
          descBottom: '',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/07.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/06.png',
          }
        },
        {
          id: 2,
          title: 'AI多语言',
          desc: '全球互联',
          descTop: '全球互联',
          descBottom: '',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/09.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/08.png',
          }
        },
        {
          id: 3,
          title: '活动管理',
          desc: '高效活动组织 拓展人脉',
          descTop: '高效活动组织',
          descBottom: '拓展人脉',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/11.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/10.png',
          }
        },
        {
          id: 4,
          title: '关注动态',
          desc: '深化商务关系  提升行业影响力',
          descTop: '深化商务关系',
          descBottom: '提升行业影响力',
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/13.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/12.png',
          }
        },
      ],
      personTabIndex: 0,
      // 轮播配置
      swiperOption1: {
        autoplay: {
          delay: 6000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false,
        },
        initialSlide: 0,
        preventClicks: true,
        hideOnClick: false,
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet',// 一般
          bulletActiveClass: 'my-bullet-active',// 选中
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        onTransitionStart(swiper) {
          console.log(swiper)
        },
        on: {
          click: (e) => { },
          slideChangeTransitionStart: (e) => {
            console.log(":eeee", e)
            setTimeout(() => {
              // this.activeIndex = this.swiper.activeIndex
            }, 50);
          },
        },
      },
      // 轮播配置
      swiperOption2: {
        autoplay: {
          delay: 6000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false,
        },
        initialSlide: 0,
        preventClicks: true,
        hideOnClick: false,
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet',// 一般
          bulletActiveClass: 'my-bullet-active',// 选中
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        onTransitionStart(swiper) {
          console.log(swiper)
        },
        on: {
          click: (e) => { },
          slideChangeTransitionStart: (e) => {
            console.log(":eeee", e)
            setTimeout(() => {
              // this.activeIndex = this.swiper.activeIndex
            }, 50);
          },
        },
      },
    }
  },
  computed: {
    swiper1() {
      return this.$refs.homeSwiper1.swiper
    },
    swiper2() {
      return this.$refs.homeSwiper2.swiper
    },
  },
  methods: {
    mouseEnter() {
      this.swiper1.autoplay.stop()
    },
    mouseLeave() {
      this.swiper1.autoplay.start();
    },
    handleChangeIndex1(index) {
      this.orgTabIndex = index
    },
    handleChangeIndex2(index) {
      this.personTabIndex = index
    },
    handleEnterApply() {
      window.location.href = "https://v.wemeet.com/#/formData?code=Q1768463872009699328"
    }
  },
  mounted() {
     this.$nextTick(()=>{
      // console.log(this.swiper1.autoplay.stop())
      // console.log(this.swiper2.autoplay.stop())
    })
   }
}
</script>
<style scoped>
@import url("../css/content.css");

.swiper-pagination /deep/ .my-bullet {
  width: 8px;
  height: 6px;
  border-radius: 6px;
  margin-left: 4px;
  background: #B5FDFF;
  display: block;
}
.swiper-pagination /deep/ .my-bullet-active {
  width: 20px;
  height: 6px;
  border-radius: 6px;
  margin-left: 4px;
  display: block;
  background: #63DDE1;
}
</style>